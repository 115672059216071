/**
 * vue-pricebox
 * @version 3.2
 * @author Čeněk Hybler <cenek.hybler@avast.com>, Jaroslav Slaby <jaroslav.slaby@avast.com>,
 * @requires avast.web.campaignMarkerCookie
 * @requires avast.web.downloadPopup
 */

(function() {
	'use strict';
	Vue.config.devtools = true;

	Vue.config.errorHandler = function(err, vm, info) {
		console.log('errorHandler');
		console.log(1, err);  // err: error trace
		console.log(2, vm);   // vm: component in which error occured
		console.log(3, info); // info: Vue specific error information such as lifecycle hooks, events etc.
		// TODO: Perform any custom logic or log to server
	};

	var detectedPlatform = avastGlobals.detect.browser.platform.toLowerCase(),
		boxWrapElement = document.querySelectorAll('.js-pricebox'), // lookup for all abox components on page
		campaignMarkerModule = avm.require('avast.web.campaignMarkerCookie')(),
		downloadPopup = avm.require('avast.web.downloadPopup');

	// Terminate if no aboxes present on the page
	// Todo : this can be defined in avast.js config where modules boot
	if (boxWrapElement.length === 0) {
		return false;
	}

	// Components
	// ---------------------------------------------------------------------------------------
	// DATA - Data passed from script to template (not tracked by reactivity)
	// PROPS - Data passed from template to script
	// METHODS - Methods are available both in script and in template
	// COMPUTED - Computed property. Not a method, doesnt accept parameters. Getters.

	// main abox-container
	Vue.component('Container', {
		template: '#container',
		delimiters: ['<%', '%>'],
		props: ['settings', 'setup', 'isfake', 'appid', 'theme', 'size'],
		data: function() {
			return {
				setup: this.setup,
				isfake: this.isfake,
				showToggler: this.setup.togglersCount > 0 ? true : false,
				themeClass: 'theme-' + this.theme,
				size: this.size
			};
		},
		computed: {
			promoClass: function() {
				return this.setup.promotedBox ? 'promo-'+ this.setup.promotedBox : false;
			},
			multiboxClass: function() {
				var
					hasMultibox = false, // info whether there is multibox among rendered boxes
					renderedBoxes = this.setup.renderedBoxes; //addresses of all displayed boxes

				// for each rendered box take address and find by it in setup if it's type is multibox
				for (var b = 0; b < Object.keys(renderedBoxes).length; b++) {
					var address = renderedBoxes[b];

					if (!hasMultibox) {
						hasMultibox = this.setup.sets[address.set].options[address.option].optionType === 'multi' ? true : false;
					}
				}
				return hasMultibox == true ? 'multibox' : false;
			},
		},
		methods: {
			changeIndex: function(event, optionIndex, setIndex, forInfluencer) {
				// optionIndex => selected option index
				// setIndex => order of the box in the multibox
				this.$emit('change_data', event, optionIndex, setIndex, forInfluencer);
			},
			getOptionData: function(address) {
				var
					optionIndex = address.option, // index of the box
					setIndex = address.set,
					setOptions = this.settings[setIndex].options,
					optionData;

				// go through options in this set, find the selected one by index and check out the pricelist
				Object.keys(setOptions).forEach(function(optionName, i) {
					if (i == optionIndex) {
						optionData = setOptions[optionName];
					}
				});
				return optionData;
			}
		}
	});


	// Toggler
	Vue.component('Toggler', {
		template: '#box-toggler',
		delimiters: ['<%', '%>'],
		props: [
			'setup',
			'settingIndex', // index of given setting
			'selectedIndex', // selected value index
			'firstBox',
			'lastBox',
			'size',
		],
		data: function() {
			return {
				hasToggler: this.setup.sets[this.settingIndex].hasToggler, // determine whether to render the toggler
				togglerValues: this.setup.sets[this.settingIndex].togglerValues, // Text values for all toggler's option in array
				horizontalToggler: this.setup.sets[this.settingIndex].horizontalToggler, // Type of toggler
				togglerTheme: this.setup.sets[this.settingIndex].togglerTheme, // Color of toggler
			};
		},
		methods: {
			isSelected: function(index){
				return index == this.selectedIndex;
			},
			togglerClick: function(event, optionIndex, setIndex, forInfluencer) {
				this.$emit('change_data', event, optionIndex, setIndex, forInfluencer);
			}
		}
	});


	// Abox
	Vue.component('Pricebox', {
		template: '#pricebox',
		delimiters: ['<%', '%>'],
		props: [
			'size',            // size of the box
			'boxOrder',        // index of the box
			'boxAddress',      // address is the object in setup with instructions where to search for data for this box in the settings
			'optionData',      // setting for the option
			'isfake',          // fake multibox
			'boxesCount',      // count of all boxes rendered
			'currentText',     // 'Current price' text for screen readers
			'originalText',    // 'Original price' text text for screen readers

			// ROW discount year periods:
			'period1st1Year',  // '/first yr' period text
			'period1st2Years', // '/first 2 yrs' period text
			'period1st3Years', // '/first 3 yrs' period text
			'period1stMonth',  // '/first month' period text

			// ROW no discount year periods + EN-GB (both discount and no discount)
			'period1Year',     // '/year' period text
			'period2Years',    // '/2 years' period text
			'period3Years',    // '/3 years' period text

			// Month period
			'periodMonth',     // '/month' period text (also for prices per month)

			// Annual texts ($XX.XX $XX.XX/first ...)
			'annualMonthly1Mnth',  // 1 month annual sentence
			'annualMonthly1Yr',  // 1 year annual sentence
			'annualMonthly2Yrs', // 2 years annual sentence
			'annualMonthly3Yrs', // 3 years annual sentence

			// Monthly prices
			'worksOutAs',      // text above price (Monthly pricing)
			'worksOutAsPrice', // text under price (Yearly pricing)

			// CTA note for ROW with discount
			'ctaAutoNoteYear',        // 'Savings compared to renewal price %fullPrice%/year.'
			'ctaAutoNoteMonth',        // 'Savings compared to renewal price %fullPrice%/month.'
			// CTA notes for EN-GB
			'ctaAutoNoteGByear', // 'It automatically renews at %fullPrice%/year unless cancelled.'
			'ctaAutoNoteGBmonth', // 'It automatically renews at %fullPrice%/month unless cancelled.'

			'subscriptionText', // Subscription details text
			'saveText',         // default label text when discounted price is in place
			'buttonDefaultText' // Default button text
		],
		data: function() {
			return {
				pricelist: this.getPricelist(),      // make price data easily accessible
				multidata: this.getMultiData(),      // have multidata generated only once per render
				spanned: 1,             // how many slots should be spanned by instructions
				ctaAutoNoteEnd: '</span>&nbsp;<span class="sub-icon"></span></span>',
			};
		},
		updated: function() {
			// Refresh data for this box after index change
			this.pricelist = this.getPricelist();
			this.multidata = this.getMultiData();
			this.setCampaignMarker();
			// lookup all download buttons in this instance and link it with DTYPopup
			var button = this.$el.querySelector('a[data-role="download-link"]:not([data-behavior="downloadPage"])');
			if(button !== null) {
				downloadPopup.setPopup(button);
			}

			// check content to determine Instruction spanning
			var count = 1;
			if (!this.getLabelText) { count++;}; //+1 for missing label
			if (count == 2 && !this.getTextAnnual) { count++;}; //+1 for From text
			if (count == 3 && !this.pricelist) { count++;}; //+1 for instalments
			if (count == 4 && !this.getVal('price_title')) { count++; }; //+1 for price/price_title
			if (count == 5 && !this.textUnderPrice) { count++; }; //+1 for note under price
			this.spanned = count;
		},
		mounted: function() {
			// once the DOM is rendered, update the content
			this.setCampaignMarker();

			// lookup all download buttons in this instance and link it with DTYPopup
			var button = this.$el.querySelector('a[data-role="download-link"]:not([data-behavior="downloadPage"])');
			if(button !== null) {
				downloadPopup.setPopup(button);
			}

			// create modal for legal text
			var modalButton = this.$el.querySelector('[data-toggle="modal"]');
			initModal(modalButton);

			// check content to determine Instruction spanning
			var count = 1;
			if (!this.getLabelText) { count++;}; //+1 for missing label
			if (count == 2 && !this.getTextAnnual) { count++;}; //+1 for From text
			if (count == 3 && !this.pricelist) { count++;}; //+1 for instalments
			if (count == 4 && !this.getVal('price_title')) { count++; }; //+1 for price/price_title
			if (count == 5 && !this.textUnderPrice) { count++; }; //+1 for note under price
			this.spanned = count;
		},
		watch: {
			getCtaNote: {
				// Everytime when getCtaNote changed:
				handler: function(val) {
					this.$nextTick(function() {
						// create modal for legal text
						var modalButton = this.$el.querySelector('[data-toggle="modal"]');
						initModal(modalButton);
					});
				}
			}
		},
		computed: {
			getPlatformIcons: function() {
				var icons = false;
				if (this.optionData.platforms != null) {
					this.optionData.platforms.forEach(function(icon, i, arr) {
						arr[i] = icon === 'pc' ? 'win' : icon; // make sure that only valid name of platform is returned
					});
					icons = this.optionData.platforms;
				}
				return icons;
			},
			getProdiconVar: function() {
				var
					value = this.getVal('product_icon').variant,
					prodIconVar =  value && value !== '' ? value : 'box'; // default variant is box
				return prodIconVar;
			},
			getProdiconName: function() {
				var
					value = this.getVal('product_icon').name,
					prodIconName = value && value !== '' ? value + (this.getProdiconVar == 'box' ? '_white' : '') : false; // concat the icon path
				return prodIconName;
			},
			getLabelVariant: function() {
				var
					value = this.getVal('labels').variant,
					labelVar = value && value !== '' ? value : 'green-bright'; // default variant is green-bright
				return labelVar;
			},
			buttonStyle: function() {
				// checks if there is defined custom button variant. Default is 'full'.
				// It is possible to send values like 'outline', 'outline dark' or 'full blue disabled' to achieve various results
				var
					value = this.getVal('button_style'),
					btnStyle = value && value != '' ? value : 'full';

				return btnStyle;
			},
			buttonTheme: function() {
				// checks if there is defined custom button theme. Default is blue.
				var
					value = this.getVal('button_theme'),
					btnThm = value && value != '' ? 'theme-' + value : 'theme-blue';

				return btnThm;
			},
			buttonText: function() {
				// checks if there is defined custom button text. Default is 'Buy Now'.
				var
					value = this.getVal('button_text'),
					btnText = value && value != '' ? value : this.buttonDefaultText;

				return btnText;
			},
			showListPrice: function() {
				// in default, always show list price unless set to 'false' in parameter
				// EN-GB has hidden list price, but can be overriden with strictly defined show_listPrice parameter as true
				if (avastGlobals.web.locale === 'en-gb' && this.getVal('show_listPrice', false) != 'true') {
					return false;
				} else {
					var result = this.getVal('show_listPrice', false) === 'false' ? false : true;
					return result;
				}
			},
			showCTANote: function() {
				var
					note = this.getVal('cta_note'), // return the cta note content or false if not found
					auto_cta_note = this.optionData['auto_cta_note'] === 'false' ? false : true; // default value is true if not set as false

				// show note when there is custom note...
				if (note) {
					return true;

				// ...or GB with pricelist (GB have always legal notes) or discount
				} else if (this.pricelist && (avastGlobals.web.locale === 'en-gb' || (this.pricelist.discountPercent && this.pricelist.discountPercent !== ''))) {
					return true;

				// no custom note but with auto note functionality
				} else if (auto_cta_note) {
					return true;

				// no custom note, no auto note functionality
				} else {
					return false;
				}
			},
			getTextAnnual: function() {
				// Returns either default value of annual price (discounted (if any)/full price) or custom content
				var
					textFrom = this.getVal('annual').text,
					value = false;

				if (this.getAnnualPrices) {
					value = this.getAnnualPrices;
				} else if (textFrom && textFrom != '') {
					value = textFrom;
				}
				return value;
			},
			getAnnualPrices: function() {
				var annualPrices = false;

				// If there is a pricelist lets do some circus with the content:
				// - detect discount price, full price and maintenance
				// - add these to legal content for given subscribtion period
				if (this.pricelist && !isYearPeriod(this.pricelist) && this.pricelist.maintenance != '1') { // evaluate only if main price is per month
					var
						discount = this.pricelist.discountPercent && this.pricelist.discountPercent !== '' ? true : false,
						maintenance = this.pricelist.maintenance ? this.pricelist.maintenance : false
					;

					if (discount) { // when discounted
						annualPrices = maintenance === '36' ? this.annualMonthly3Yrs : (maintenance === '24' ? this.annualMonthly2Yrs : (maintenance === '12' ? this.annualMonthly1Yr : this.annualMonthly1Mnth));

						var yearPriceDiscounted = this.getVal('yearPriceDiscounted'+maintenance),
								yearPriceFull = this.getVal('yearPriceFull'+maintenance);

						// Prices placeholders replacement
						annualPrices = yearPriceDiscounted && yearPriceFull ? annualPrices.replace('$$', yearPriceFull).replace('ŁŁ', yearPriceDiscounted) : annualPrices;

					} else {
						var annualPricesPeriod = maintenance === '36' ? this.period3Years : (maintenance === '24' ? this.period2Years : (maintenance === '12' ? this.period1Year : this.periodMonth));
						annualPrices = this.getVal('yearPriceFull'+maintenance) + annualPricesPeriod;
					}

					// set A11y screen reader hidden text (stroke tags replacement)
					if (/<s>.+<\/s>/.test(annualPrices)) {
						annualPrices = annualPrices.replace(/<s>/, '<span class="visually-hidden">' + this.originalText + '</span><s class="price-old">').replace(/<\/s>/, '</s> <span class="visually-hidden">'+ this.currentText + '</span>')
					}
				}
				return annualPrices ;
			},
			getCtaNote: function() {
				var
					noteContent = this.getVal('cta_note'), // return the cta note content or false if not found
					auto_cta_note = this.optionData['auto_cta_note'] === 'false' ? false : true; // check whether use automatic formatting for the CTA Note

				// If there is a pricelist, lets do some circus with the content:
				if (this.pricelist && auto_cta_note) {
					var
						discount = this.pricelist.discountPercent && this.pricelist.discountPercent !== '' ? this.pricelist.discountPercent : false, // set the discount value or false if not found
						maintenance = this.pricelist.maintenance ? this.pricelist.maintenance : false,
						
						ctaAutoNote = '',
						fullPrice = '',
						billingContent = '',
						subscriptionContent = '';

					// GB legal content is different than others
					if (avastGlobals.web.locale === 'en-gb'){
						ctaAutoNote = maintenance === '1' ? this.ctaAutoNoteGBmonth : this.ctaAutoNoteGByear; // default text
						fullPrice = maintenance === '1' ? this.pricelist.priceFormatted : this.getVal('yearPriceFull12'); // full price for month/year subscription period

						// Subscription modal link wrapper and price placeholder replacement
						noteContent = this.legalSuffixStart() + ctaAutoNote.replace('$$', fullPrice) + this.ctaAutoNoteEnd;

					// ROW legal content
					} else {
						if (discount) {
							ctaAutoNote = maintenance === '1' ? this.ctaAutoNoteMonth : this.ctaAutoNoteYear; // default text
							fullPrice = maintenance === '1' ? this.pricelist.priceFormatted : this.getVal('yearPriceFull12'); // full price for month/year subscription period

							billingContent = fullPrice ? ctaAutoNote.replace('$$', fullPrice) : ctaAutoNote;
						}

						// Add legal content to whatever note content
						var legalSuffix = this.legalSuffixStart() + this.subscriptionText + this.ctaAutoNoteEnd;

						// Fix of sentence ending characters
						if (billingContent.length > 0) {
							var specialDotLocales = ['ja-jp', 'zh-cn', 'zh-tw'];

							if (specialDotLocales.indexOf(avastGlobals.web.locale) > -1) {
								var fixedDot = (billingContent.slice(-1) !== '。') ? '。 ' : ' ';
							} else {
								var fixedDot = (billingContent.slice(-1) !== '.') ? '. ' : ' ';
							}
							subscriptionContent = billingContent + fixedDot + legalSuffix; // adds period (if it's missing) and Subscription modal link
						} else {
							subscriptionContent = legalSuffix; // Subscription modal link only
						}

						// Join all legal texts together
						if (subscriptionContent.length > 0) {
							noteContent = noteContent ? (noteContent + '<br>' + subscriptionContent) : subscriptionContent;
						} else {
							noteContent = noteContent ? (noteContent + '<br>' + billingContent) : billingContent;
						}
					}
				}
				return noteContent;
			},
			getLabelText: function() {
				var
					label = this.getVal('labels').text,
					value = label && label !== '' ? label : false;

				// if there is not custom text defined, check if there is a discount in pricelist
				if (!value) {
					if (this.pricelist && this.pricelist.discountPercentFormatted && this.pricelist.discountPercentFormatted !== '') {
						value = this.saveText.replace('$$', this.pricelist.discountPercentFormatted);
					}
				}
				return value;
			},
			textUnderPrice: function() {
				var
					oneTimeService = this.getVal('period_text'),
					textUnderPrice = this.getVal('note_under_price'),
					calculatedText,
					monthlyPrice;

				// If there is custom period (i.e. '/call'), lets assume that the pricebox is one time service (Total Care)
				if (!oneTimeService) {
					// if yearly price is displayed -----------------------------
					if (this.pricelist && isYearPeriod(this.pricelist)){
						// retrieve monthly price from pricelist
						if (this.pricelist.realPriceRoundedPerMonth && this.pricelist.realPriceRoundedPerMonth !== '') {
							var monthlyPrice = this.pricelist.priceFormat.replace('#c', this.pricelist.currencySymbol).replace('#p', this.pricelist.realPriceRoundedPerMonth);
							calculatedText = this.worksOutAsPrice.replace('$$', '<b>'+monthlyPrice+'</b>');
						}
						// if any custom text was defined, attach it after the monthly price
						return textUnderPrice ? (calculatedText + '<br>' + textUnderPrice) : calculatedText;

					// if no pricelist, show only custom text ----------------------
					} else {
						return textUnderPrice;
					}
				} else {
					return false
				}
			},
			textAbovePrice: function() {
				var textAbovePrice = false;

				// if monthly price is displayed, show it 'It works out as'
				if (this.pricelist && !isYearPeriod(this.pricelist) && this.pricelist.maintenance != '1'){
					textAbovePrice = this.worksOutAs;
				}
				return textAbovePrice;
			},
			decimalPart: function() {
				return this.pricelist.priceDecimalPart != '' ? this.pricelist.decimalSeparator + this.pricelist.priceDecimalPart : '';
			},
			getSecondaryTextPeriod: function() {
				if (this.period) {
					// custom text for period (e.g. /call)
					return this.period;
				} else if (isYearPeriod(this.pricelist)) {
					// price is yearly
					return this.pricelist.maintenance === '36' ? this.period1st3Years : (this.pricelist.maintenance === '24' ? this.period1st2Years : this.period1st1Year);
				} else {
					// price is monthly
					return this.period1stMonth;
				}
			}
		},
		methods: {
			getVal: function(paramName, convertToBoolean) {
				var
					boolCheck = convertToBoolean != null ? convertToBoolean : true, // default is true - always convert string boolean to boolean
					paramArr = this.optionData[paramName] ? this.optionData[paramName] : false, // retrieve value of given parameter
					isString = false,
					boxPriceIndex = 0,
					value = false,
					paramValue;

				// Check type of parameter
				if (paramArr && paramArr !== '') {
					isString = typeof(paramArr) === 'string' ? true : false, // check if value is just a string
					boxPriceIndex = paramArr instanceof Array ? this.boxAddress.price : this.boxAddress.price + 1;// control of param type (array or object). (pressume there will be no other types entered (please))
				}

				// if box is not part of multibox and only string then return as it is (used for singleboxes or same value for all boxes(e.g. period_text))
				if (isString) {
					value = paramArr;

					// if NOT a string, takes only the first item in object/array or from proper index
				} else {
					value = paramArr[boxPriceIndex] && paramArr[boxPriceIndex] !== '' ? paramArr[boxPriceIndex] : false;
				}

				if (boolCheck) {
					// check whether there is true/false value as string and convert it to boolean
					paramValue = !value || value === 'false' ? false : (value === 'true' ? true : value);
				} else {
					paramValue = value;
				}

				return paramValue;
			},
			legalSuffixStart: function() {
				var
					modalPeriod = avastGlobals.web.locale === 'en-gb' ? (isYearPeriod(this.pricelist) ? 'data-period="y"' : 'data-period="m"') : '',
					ctaAutoNoteStart = '<span class="subscription-link" data-toggle="modal" '+modalPeriod+' data-target="pricebox-modal" tabindex="0" role="button"><span class="sub-text">';
				return ctaAutoNoteStart;
			},
			setCampaignMarker: function() {
				var cartLinks = this.$el.querySelectorAll('[data-role="cart-link"]');

				// Update campaign marker data and href attributes with __trSrc cookie
				if (cartLinks.length) {
					campaignMarkerModule.setCampaignMarkerCookie(cartLinks);
				}
				// TODO - Description of this code:
				if(avastGlobals.web.abtest !== undefined) {
					cartLinks.forEach(function(el) {
						var dataCM = el.getAttribute('data-campaign-marker').split('~'),
							hrefCM = el.getAttribute('href').split('~'),
							abTest = avastGlobals.web.abtest.ticketNumber,
							testVariant = avastGlobals.web.abtest.testVariant;

						dataCM[3] = abTest;
						dataCM[4] = testVariant;
						hrefCM[3] = abTest;
						hrefCM[4] = testVariant;
						el.setAttribute('data-campaign-marker', dataCM.join('~'))
						el.setAttribute('href', hrefCM.join('~'))
					})
				};
				return cartLinks;
			},
			getPricelists: function() {
				// Return array of pricelists for this option
				var optionPricelists = false;

				if (this.optionData.pricelist != null) {
					optionPricelists = this.optionData.pricelist;
				}
				return optionPricelists;
			},
			getPricelist: function() {
				// Return pricelist for this box defined by price index in address
				var
					pricelists = this.getPricelists(),
					priceIndex = this.boxAddress.price,
					pricesCount = Object.keys(pricelists).length,
					optionPricelist = false;

				if (pricesCount > 0) {
					Object.keys(pricelists).forEach(function(productId, j) {
						if (j == priceIndex) {
							optionPricelist = pricelists[productId];
						}
					});
				}
				return optionPricelist;
			},
			getMultiData: function() {
				// detection if this box is part of multibox or fake multibox and what is it's order in the scope of this (fake)multibox
				var
					pricelists = this.getPricelists(),
					listCount = pricelists ? Object.keys(pricelists).length : 0,
					multibox = false;

				if (this.isfake && this.boxesCount > 1) {
					if (this.boxOrder == 1) {
						multibox = 'start';
					} else if (this.boxOrder == this.boxesCount) {
						multibox = 'end';
					} else {
						multibox = true;
					}

				} else if (listCount > 1) {
					if (this.boxAddress.price == 0) {
						multibox = 'start';
					} else if (this.boxAddress.price == listCount - 1) {
						multibox = 'end';
					} else {
						multibox = true;
					}
				}
				return multibox;
			}
		}
	});

	// Price
	Vue.component('Price', {
		template: '#box-price',
		delimiters: ['<%', '%>'],
		props: [
			'pricelist',
			'period',
			'campaign',
			'showListPrice',
			'size',
			'period1st1Year',
			'period1st2Years',
			'period1st3Years',
			'period1stMonth',
			'period1Year',
			'period2Years',
			'period3Years',
			'periodMonth',
		],
		computed: {
			decimalPart: function() {
				return this.pricelist.priceDecimalPart != '' ? this.pricelist.decimalSeparator + this.pricelist.priceDecimalPart : '';
			},
			getMainPricePeriod: function() {
				var maintenance = this.pricelist.maintenance;
				var discount = this.pricelist.discountPercent && this.pricelist.discountPercent !== '' ? true : false;

				if (this.period) {
					// custom text for period (e.g. /call)
					return this.period;
				} else if (isYearPeriod(this.pricelist)) {
					// price is yearly
					if (discount) {
						return maintenance === '36' ? this.period1st3Years : (maintenance === '24' ? this.period1st2Years : this.period1st1Year);
					} else {
						return maintenance === '36' ? this.period3Years : (maintenance === '24' ? this.period2Years : this.period1Year);
					}
				} else {
					// price is monthly
					return discount && maintenance === '1' ? this.period1stMonth : this.periodMonth;
				}
			},
			isLongPeriod: function() {
				// Long period meand there is a discount 
				// this will detect what kind of period is used and allow to apply class
				// - yearly pricing for yearly subscriptions
				// - discount
				var discount = this.pricelist.discountPercent && this.pricelist.discountPercent !== '' ? true : false;

				return discount && this.pricelist.priceFlag != "M";
			},
		}
	});



	// Init (One VUE object for each abox element on page)
	// --------------------------------------------------------------------------------------------------------------
	for (var i = 0; i < boxWrapElement.length; i++) {
		var element = boxWrapElement[i],
			settings = JSON.parse(element.getAttribute('data-settings')),
			isfake = element.getAttribute('data-fake'),
			appid = element.getAttribute('id');

		// MAIN PARENT OBJECT
		// --------------------------------------------------------------------------------------------------------------
		element = new Vue({
			el: element,
			delimiters: ['<%', '%>'],
			data: function() {
				return {
					settings: settings,
					isfake: isfake === 'true' ? true : false,
					appid: appid,
					lastTogglerSwitch: {
						"setIndex": 0,
						"optionIndex": 0,
					},
					setup: {
						"settingsCount": settings.length, // count of all settings (#)
						"togglersCount": 0,        // togglers count to add class to container
						"renderedBoxesCount": 0,   // how many aboxes should be rendered based on default indexes of all settings
						"renderedBoxes": [],       // addresses of rendered boxes
						"sets": [],
						"promotedBox": false,      // to set proper class for promoted box
					}
				};
			},
			created: function () {
				// Values for the Setup
				// --------------------------------------------------------------------------------------------------------------
				// - settings - all settings of whole CONTAINER
				// - setting - single setting (rendered as separate ABOX/MULTIBOX)
				// - option - values of an option in the given box (setting) (switched with toggler)
				for (var s = 0; s < this.settings.length; s++) {
					var
						setting = this.settings[s], // each setting from settings
						defaultIndex = 0, // default index per setting is 0
						hasToggler = false, // is there a toggler for this setting?
						horizontalToggler, // type of toggler
						togglerTheme, // color of toggler
						settingOptionsNames = Object.keys(setting.options); // array of this setting options names

					// Togglers count ---------------------------
					// if the given setting has more options, there will be toggler
					// also if there is only one option but name must bedefined and this toggler will be 'disabled'
					if (settingOptionsNames.length > 1 || (settingOptionsNames.length == 1 && setting.options[settingOptionsNames[0]]['toggler_text'])) {
						this.setup.togglersCount++;
						hasToggler = true; // show toggler if there is more than 1 option
						horizontalToggler = setting.horizontal_toggler != undefined && setting.horizontal_toggler === 'true' ? true : false;
						togglerTheme = setting.toggler_theme != undefined && setting.toggler_theme != '' ? 'bg-' + (setting.toggler_theme == 'gray' ? 'gray07' : setting.toggler_theme) : 'bg-orange-pale';
					}

					// Default option index ----------------------------
					if (setting.default_option !== undefined) { // default_option is defined in parameter
						defaultIndex = setting.default_option - 1; // default index from settings minus 1 so it match with iteration index

					} else if (setting.platform_detect === 'true') { // default_option not defined but platform detection is active

						// Check if there is option with same platform as detected user's platform
						// NOTE: if options in dataset are not ordered by number, the toggler will not match!!! Reason is that index is not option name.
						defaultIndex = searchPlatform(setting, detectedPlatform); // Lookup for option in given setting for detected platform

						if (defaultIndex === false) { // if there is no option for detected platform, look up for option with default platform
							if (!setting.default_platform) { // if there is no default platform defined, use first option
								defaultIndex = 0;
							} else {
								defaultIndex = searchPlatform(setting, setting.default_platform);

								// TODO - check if setting.default_platform is a string or array. If string, use just one value. If array, search for default platform for detected platform.
							}

							if (defaultIndex === false) { // if no option with default platform is found, use first option
								defaultIndex = 0;
							}
						}
					} else { // if there is no default_option and no platform_detect, use first option
						defaultIndex = 0;
					}

					// add values of this setting to the Setup
					this.addSet(defaultIndex, hasToggler, horizontalToggler, togglerTheme, 1, 1);

					// Option values ------------------------------
					for (var o = 0; o < settingOptionsNames.length; o++) {
						var
							optionName = settingOptionsNames[o],
							optionSetting = setting.options[optionName],
							pricelistsCount = optionSetting['pricelist'] !== undefined ? Object.keys(optionSetting['pricelist']).length : 0,
							campaign = optionSetting['campaign'] !== undefined ? optionSetting['campaign'] : false,
							promotedBox = optionSetting.promoted_box !== undefined ? parseFloat(optionSetting.promoted_box) : false,
							togglerText = optionSetting['toggler_text'] !== undefined ? optionSetting['toggler_text'] : ('option_'+ (o+1)),
							boxesCount = 0; // count of rendered boxes in the option

						// how many boxes should be rendered for this option
						if (pricelistsCount >= 1) {
							boxesCount = pricelistsCount;
						} else if (pricelistsCount == 0) {
							boxesCount = 1;
						}

						// toggler values of this option in this set
						if (optionSetting['toggler_text'] != undefined)
						this.setup.sets[s].togglerValues.push(togglerText);

						// add values of this option to the Setup
						this.addOption(s, boxesCount, optionType(pricelistsCount, this.isfake), promotedBox, campaign);
					};
				};
				this.updateSetup(); // calculate toggler positions in grid and boxcounts

				// ---------------- TABLE INFLUENCER LISTENER ----------------
				var
					thisApp = this, // required to pass into EventListener!
					thisAppTable = element.closest(".table.with-influencer"); // find the closest parent table with influencer and hang listener on it

				if (thisAppTable) {
					thisAppTable.addEventListener("change", function(e){
						var isAppParentBottom = thisApp.$el.closest(".bottom-price-tr"); // check if this app is in bottom pricing table row
						var eventFromPricebox = e.target.closest('.box-toggler') ? e.target.closest('.box-toggler') : false; // check if event was triggered from pricebox

						// Get parent of the select element
						if (e.target.closest(".table-header-tr")) {
							var eventParentPosition = 'header';
						} else if (e.target.closest(".bottom-price-tr")) {
							var eventParentPosition = 'bottom';
						}

						if (eventParentPosition == 'header' && !eventFromPricebox) {
							var influencerCell = -1;
							var eventParent = e.target.closest(".table-header-tr .cell");

							// Get index of .cell node with event triggering influencer
							while((eventParent = eventParent.previousElementSibling) != null) {
								influencerCell++;
							}

							// Update priceboxes by data from Influencer Select only if its position was calcluated
							if (influencerCell != -1) {
								if (isAppParentBottom) {
									// this should choose the proper option in dropdown directly in the bottom pricebox if the event comes from Influencer
									var newOption = thisApp.$el.querySelectorAll('.box-toggler[set="'+(influencerCell+1)+'"] select option')[e.target.selectedIndex];
									if (newOption) {newOption.selected = true;} // set the option as selected
								}

								thisApp.changeIndex(e, e.target.selectedIndex, influencerCell, false);
							}

						} else if (eventParentPosition == 'bottom' && !isAppParentBottom) {
							var selectSet = eventFromPricebox.getAttribute('set') - 1;
							var parentBoxOptionsCount = thisApp.setup.sets[selectSet].options.length; // get the top price row parent

							// Check whether there is more sets in upper pricebox or not
							if (parentBoxOptionsCount > 1) {
								thisApp.changeIndex(e, e.target.selectedIndex, selectSet, true);
							}
						}
					});
				}
				// ---------------- / INFLUENCER LISTENER ----------------
			},
			mounted: function() {
				this.$nextTick(function(){window.dispatchEvent(this.eventData())}) // send global event on creation
			},
			methods: {
				changeIndex: function(event, optionIndex, setIndex, forInfluencer) {
					// optionIndex => selected option index
					// setIndex => order of the box in the multibox

					this.setup.sets[setIndex].selectedIndex = optionIndex; // update setup with selected option index
					this.updateSetup(); // recalculate the rest of setup
					this.setLastTogglerSwitch(setIndex, optionIndex); // update info about last switched toggler
					this.$nextTick(function(){
						window.dispatchEvent(this.eventData(forInfluencer)); // send global event on toggler change
					});
				},

				// update information about which toggler was changed last
				setLastTogglerSwitch: function(setIndex, optionIndex) {
					this.lastTogglerSwitch.setIndex = setIndex;
					this.lastTogglerSwitch.optionIndex = optionIndex;
				},

				// add setting to the Setup
				addSet: function(selectedIndex, hasToggler, horizontalToggler, togglerTheme) {
					var newSet = {
						"selectedIndex": selectedIndex,
						"hasToggler": hasToggler,
						"horizontalToggler": horizontalToggler,
						"togglerTheme": togglerTheme,
						"firstBox": 0,
						"lastBox": 0,
						"togglerValues": [],
						"options": []
					}
					this.setup.sets.push(newSet);
				},

				// add option to parent setting in the Setup
				addOption: function(setIndex, boxesCount, optionType, promotedBox, campaign) {
					var newOption = {
						"boxesCount": boxesCount,
						"optionType": optionType,
						"promotedBox": promotedBox,
						"campaign": campaign
					}
					this.setup.sets[setIndex].options.push(newOption);
				},

				// calculate setup params (while init and when togglers change status)
				updateSetup: function() {
					var
						boxesCountSum = 0,   // box count init value
						start = 0,           // toggler start column- init value
						hasPromo = false,    // promo detection init value
						addresses = [];      // array for rendered boxes setup addresses

					for (var s = 0; s < this.setup.settingsCount; s++) {
						var
							set = this.setup.sets[s],
							selectedIndex = set.selectedIndex, // selected option index
							selectedOption = set.options[selectedIndex], //
							optionBoxes = selectedOption.boxesCount; // how many has selected option boxes

						// calculate box count and define addresses of rendered boxes
						if (optionBoxes >= 1) {
							boxesCountSum += optionBoxes;

							for (var p = 0; p < optionBoxes; p++) {
								addresses.push({
									"set": s,
									"option": selectedIndex,
									"price": p
								});
							}
						} else if (optionBoxes == 0) {
							boxesCountSum++;

							addresses.push({
								"set": s,
								"option": selectedIndex,
								"price": 1
							});
						}
						this.setup.renderedBoxesCount = boxesCountSum;

						// toggler position and span
						set.firstBox = start + 1;
						set.lastBox = start + optionBoxes;
						start += optionBoxes;
					}
					this.setup.renderedBoxes = addresses;

					// promoted box index detection (i.e. which of rendered option of a set has the promotion - first found serves)
					addresses.forEach(function(boxAddress, i) {
						var
							set = boxAddress.set,       // this setting index
							option = boxAddress.option, // this option index
							price = boxAddress.price,   // this option pricelist index
							promotedBox = this.sets[set].options[option].promotedBox; // order of promoted box in this option

						// if promo wasn't found yet during the loop
						if (!hasPromo && price+1 == promotedBox) {
							// if there is value in the setting, the assign the value
							hasPromo = promotedBox && promotedBox != null ? promotedBox : false;

							// if promo is not false and it is in the range of count of rendered boxes, then assign the value
							hasPromo = hasPromo && hasPromo > 0 && hasPromo <= boxesCountSum ? i + 1 : false;
						}
					}, this.setup);
					this.setup.promotedBox = hasPromo;
				},
				eventData: function(forInfluencer) {
					return new CustomEvent('pricebox-updated', {
						'detail': {
							'element':this.$el,
							'setIndex': this.lastTogglerSwitch.setIndex,
							'optionIndex': this.lastTogglerSwitch.optionIndex,
							'forInfluencer': forInfluencer
						}
					});
				}
			}
		});
	};


	// METHODS
	// --------------------------------------------------------------------------------------------------------------

	// Type of option based on number of pricelists
	function optionType(number, fake) {
		if (number > 1 || fake === true) {
			return "multi";
		} else if (number == 1) {
			return "single";
		} else {
			return "trial";
		}
	};

	// Checks if price is monthly
	function isYearPeriod(obj) {
		if (obj.priceFlag == "M" || obj.maintenance == 1) {
			return false;
		} else {
			return true;
		}
	}

	// Lookup the user platform in given dataset.
	// Will look up such option where detected platform matches with value in parameter 'platform'. If there are more with the same name, the last one is selected
	function searchPlatform(dataset, platform) {
		var returnedValue = false;

		Object.keys(dataset.options).forEach(function(optionName, i) {
			if ('platform' in dataset.options[optionName]) {
				if (platform.toUpperCase() === dataset.options[optionName].platform.toUpperCase()) {
					returnedValue = i;
				}
			}
		});
		return returnedValue;
	}

	// Legal notes modal initialization
	function initModal(modalButton) { // button triggering the modal
		var
			modalTarget = modalButton !== null ? modalButton.getAttribute('data-target') : null,
			modalPeriod = modalButton !== null ? modalButton.getAttribute('data-period') : null;

		// load modal in the moment of user interaction
		if (modalButton !== null && modalTarget !== null) {
			// pass modal name and optional period to modal loader
			modalButton.addEventListener('click', function() {
				loadModal(modalTarget, modalPeriod);
			});
			modalButton.addEventListener('keypress', function(e) {
				if (e.charCode === 13) {
					loadModal(modalTarget, modalPeriod);
				}
			});
		}
	}

	function loadModal(target, period) {
		var
			periodName = period !== null && period !== '' ? ('-' + period) : '',
			targetName = target + periodName;


		/* ATTENTION: make sure name of source page for modal and target in modal trigger are same!!
		- pricebox-modal => Global legal text
		- pricebox-modal-gb => GB yearly legal text
		- pricebox-modal-gb-month => GB monthly legal text
		*/
		// if modal with name in the target is not on page
		if(document.getElementById(targetName) === null) {
			var
				contentHolder = document.querySelector('.content-holder'),
				xhr = new XMLHttpRequest();

			xhr.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					var container = document.createElement('div');

					container.innerHTML = this.responseText;
					contentHolder.append(container);

					var modal = new BSN.Modal(document.getElementById(targetName));
					modal.show();
				}
			};
			xhr.open('GET', 'https://' + avastGlobals.web.domain + avastGlobals.web.ulocale + '/pricebox-modal?p=' + period, true);
			xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');
			xhr.send(null);

		} else {
			var modal = new BSN.Modal(document.getElementById(targetName));
			modal.show();
		}
	}
})();
